import React, { useContext, useState } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";

import { Modal } from "react-bootstrap";
import PsContext from "../../../context/PsContext";
import LoginForm from "../../pages/userAccount/loginForm";
import TopSearchBar from "../header/topSearchBar";
const TopHeader = () => {
  const context = useContext(PsContext);

  return (
    <div className="main-header line">
      <div className="container-full px_15 lg-px_40">
        <div className="row wrapper-header align-items-center">
          <div className="col-xl-10 tf-md-hidden">
            <ul className="header-list-categories">
              <li className="categories-item">
                <Link to="/about">About Us</Link>
              </li>
              {/* <li className="categories-item">
                <Link to="/size-chart">Size Chart</Link>
              </li> */}
              <li className="categories-item">
                <Link to="/privacy-policy">Privacy Policy</Link>
              </li>
              <li className="categories-item">
                <Link to="/refund-policy">Refund Policy</Link>
              </li>
              <li className="categories-item">
                <Link to="/shipping-policy">Shipping Policy</Link>
              </li>
              <li className="categories-item">
                <Link to="/disclaimer">Disclaimer</Link>
              </li>
            </ul>
          </div>

          {/* <div className="col-md-5 col-3 tf-md-hidden">
            <ul className="header-list-categories">
              <li className="categories-item">
                <a href={`mailto:${context.state.company.email}`}>
                  <i className="icon fs-12 icon-mail"></i>{" "}
                  {context.state.company.email}
                </a>
              </li>
              <li className="categories-item">
                <a href="#" className="text-uppercase">
                  Phone: {context.state.company.mobile}
                </a>
              </li>
            </ul>
          </div> */}

          <div className="col-md-2 col-3 tf-md-hidden">
            <div className="tf-top-bar_wrap grid-3 gap-30">
              <ul className="tf-top-bar_item tf-social-icon d-flex gap-10  align-items-end">
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-facebook bg_line"
                  >
                    <i className="icon fs-12 icon-fb"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-twiter bg_line"
                  >
                    <i className="icon fs-10 icon-Icon-x"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-instagram bg_line"
                  >
                    <i className="icon fs-12 icon-instagram"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-tiktok bg_line"
                  >
                    <i className="icon fs-12 icon-tiktok"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="box-icon w_28 round social-pinterest bg_line"
                  >
                    <i className="icon fs-12 icon-pinterest-1"></i>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
