import React, { useState, useEffect } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "axios";
import { Row, Col } from "react-bootstrap";

import { Api } from "../../../utils/Variables";
import { ServiceUrl } from "../../../utils/ServiceUrl";

const CategoriesMenu = ({ location, history }) => {
  const [categories, setCategories] = useState([]);
  const [showMenu, setShowMenu] = useState(true);

  useEffect(() => {
    axios.get(`${Api.Url}${ServiceUrl.MENU.MEGA_MENU}`).then((res) => {
      if (res.data.status === "1") {
        setCategories(res.data.data);
      }
    });
  }, []);

  useEffect(() => {
    setShowMenu(true);
  }, [location.pathname]);

  const handleLinkClick = (slug) => {
    setShowMenu(false);

    const targetPath = `/category/${slug}`;
    if (location.pathname === targetPath) {
      history.replace("/");
      setTimeout(() => {
        history.push(targetPath);
      }, 0);
    } else {
      history.push(targetPath);
    }
  };

  const mainCategories = () => {
    return categories.map((item, i) => (
      <Col key={i}>
        <div className="list-group list-group-flush list-group-no-border list-group-sm">
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleLinkClick(item.slug);
            }}
            className="list-group-item list-group-item-action text-white"
          >
            <strong>{item.categoryname.toUpperCase()}</strong>
          </a>
          {item.subcategory && item.subcategory.length > 0 ? (
            <div className="subcategories text-white">
              {singleCategory(item.subcategory)}
            </div>
          ) : (
            <div className="no-subcategories"></div>
          )}
        </div>
      </Col>
    ));
  };

  const singleCategory = (items) => {
    return items.map((item, i) => (
      <a
        key={i}
        href="#"
        onClick={(e) => {
          e.preventDefault();
          handleLinkClick(item.slug);
        }}
        className="list-group-item list-group-item-action text-white"
      >
        {item.categoryname}
      </a>
    ));
  };

  return showMenu ? (
    <div
      className="dropdown-menu"
      aria-labelledby="megaDropdown"
      style={{ width: "1100px", left: "-280px", backgroundColor: "#f1827b" }}
    >
      <Row>{mainCategories()}</Row>
    </div>
  ) : null;
};

export default withRouter(CategoriesMenu);
