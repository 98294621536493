import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Form, Button, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import $ from "jquery";
import { toast } from "react-hot-toast";

import PsContext from "../../../context/PsContext";
import API from "../../../utils/API";

const styles = {
  spinnerStyle: {
    position: "absolute",
    bottom: "4px",
    right: "13px",
  },
};

const UpdateAddress = (props) => {
  const context = useContext(PsContext);
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [inputLoader, setInputLoader] = useState(null);

  const loadCountries = () => {
    setInputLoader("country");
    API.get("v1/site/getwhich/country/all").then((res) => {
      if (res["data"].status == "1") {
        setCountries(res["data"].data);
        if (context.state.userData.shipping_country)
          loadStates(context.state.userData.shipping_country);
      }
      setInputLoader(null);
    });
  };

  const loadStates = (countryId) => {
    setInputLoader("state");
    API.get(`v1/site/getwhich/country/${countryId}`).then((res) => {
      if (res["data"].status == "1") {
        setStates(res["data"].data);
      }
      setInputLoader(null);
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    if (form.checkValidity() === false) {
      e.stopPropagation();
      setValidated(true);
      return;
    }
    setLoading(true);
    API.post("v1/site/updateaddress", $("#frm_userAddress").serialize()).then(
      (res) => {
        if (res["data"].status == "1") {
          toast.success("Address Updated");
          context.userLogin(res["data"].data);
          if (props.afterFinish) props.afterFinish();
          setLoading(false);
        } else {
          toast.error(res["data"].message, {
            style: {
              marginTop: "5%",
              borderRadius: "1px",
              background: "#333",
              color: "#fff",
            },
          });
          setLoading(false);
        }
      }
    );
  };

  useEffect(() => {
    loadCountries();
  }, []);

  return (
    <Form
      action=""
      method="post"
      id="frm_userAddress"
      noValidate
      validated={validated}
      onSubmit={handleSubmit}
    >
      <input type="hidden" name="id" value={context.state.userData.id} />
      <Row>
        <Col md={6}>
          <label>
            Mobile <span className="text-danger">*</span>
          </label>
          <Form.Control
            type="text"
            value={context.state.userData.mobile}
            className="bg-light"
            name="mobile"
            readonly
          />
        </Col>
        <Col md={6}>
          <label>
            Email <span className="text-danger">*</span>
          </label>
          <Form.Control
            type="email"
            defaultValue={context.state.userData.email}
            name="email"
            required
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={12}>
          <label>
            Your Name <span className="text-danger">*</span>
          </label>
          <Form.Control
            type="text"
            defaultValue={context.state.userData.contactname}
            name="contactname"
            required
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={6}>
          <label>
            Address <span className="text-danger">*</span>
          </label>
          <Form.Control
            type="text"
            defaultValue={context.state.userData.shipping_address_1}
            name="shipping_address_1"
            required
          />
        </Col>
        <Col md={6}>
          <label>
            City <span className="text-danger">*</span>
          </label>
          <Form.Control
            type="text"
            defaultValue={context.state.userData.shipping_city}
            name="shipping_city"
            required
          />
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={6}>
          <label>
            Country <span className="text-danger">*</span>
          </label>
          <Form.Control
            as="select"
            name="shipping_country"
            required
            onChange={(e) => loadStates(e.target.value)}
          >
            {inputLoader && inputLoader == "country" && (
              <option value="">Please wait..</option>
            )}
            {countries && countries.length > 0 && (
              <option value="">Select Your Country</option>
            )}
            {countries &&
              countries.length > 0 &&
              countries.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    selected={
                      item.id == context.state.userData.shipping_country
                    }
                  >
                    {" "}
                    {item.name}{" "}
                  </option>
                );
              })}
          </Form.Control>
          {inputLoader && inputLoader == "country" && (
            <Spinner animation="border" size="md" style={styles.spinnerStyle} />
          )}
        </Col>
        <Col md={6}>
          <label>
            State <span className="text-danger">*</span>
          </label>
          <Form.Control as="select" name="shipping_state" required>
            {inputLoader && inputLoader == "state" && (
              <option value="">Please wait..</option>
            )}
            {states && states.length > 0 && (
              <option value="">Select Your State</option>
            )}
            {states &&
              states.length > 0 &&
              states.map((item, i) => {
                return (
                  <option
                    value={item.id}
                    selected={item.id == context.state.userData.shipping_state}
                  >
                    {" "}
                    {item.name}{" "}
                  </option>
                );
              })}
          </Form.Control>
          {inputLoader && inputLoader == "state" && (
            <Spinner animation="border" size="md" style={styles.spinnerStyle} />
          )}
        </Col>
      </Row>

      <Row className="mt-3">
        <Col md={6}>
          <label>
            Pincode <span className="text-danger">*</span>
          </label>
          <Form.Control
            type="text"
            defaultValue={context.state.userData.shipping_zip}
            name="shipping_zip"
            required
          />
        </Col>
        <Col md={6} style={{ marginTop: "30px" }}>
          <Button
            type="submit"
            block
            className="bg-theme border-0 fw-bold"
            disabled={loading}
          >
            {loading ? (
              <span>
                <Spinner as="span" size="sm" role="status" aria-hidden="true" />
                &nbsp; Please wait..
              </span>
            ) : (
              "Continue"
            )}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};
export default UpdateAddress;
