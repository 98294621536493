import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import axios from "axios";

import { Api } from "../../../utils/Variables";
import { ServiceUrl } from "../../../utils/ServiceUrl";

const CategoryTypeMenu = ({ type, limit, title, location, history }) => {
  const [categories, setCategories] = useState([]);
  const [showMenu, setShowMenu] = useState(true);

  const loadCategories = () => {
    axios
      .get(`${Api.Url}${ServiceUrl.MENU.SMALL_MENU}${type}/${limit}`)
      .then((res) => {
        if (res.data.status === "1") {
          setCategories(res.data.data);
        }
      });
  };

  useEffect(() => {
    loadCategories();
  }, []);

  useEffect(() => {
    setShowMenu(true);
  }, [location.pathname]);

  const handleLinkClick = (slug) => {
    setShowMenu(false);

    const targetPath = `/category/${slug}`;
    if (location.pathname === targetPath) {
      history.replace("/");
      setTimeout(() => {
        history.push(targetPath);
      }, 0);
    } else {
      history.push(targetPath);
    }
  };

  const mainCategories = () => {
    return categories.map((item, i) => (
      <a
        key={i}
        href="#"
        className="dropdown-item"
        onClick={(e) => {
          e.preventDefault();
          handleLinkClick(item.slug);
        }}
      >
        {item.categoryname}
      </a>
    ));
  };

  return (
    <div className="nav-item dropdown dropdown-hover">
      <a
        className="nav-link dropdown-toggle no-caret"
        href="#"
        id="pagesDropdown"
        role="button"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        {title}
      </a>
      {showMenu && (
        <div className="dropdown-menu" aria-labelledby="pagesDropdown">
          {mainCategories()}
        </div>
      )}
    </div>
  );
};

export default withRouter(CategoryTypeMenu);
