import React, { Memo, useContext, useEffect, useState } from "react";
import { Redirect, Link, NavLink } from "react-router-dom";
import $ from "jquery";

import PsContext from "../../../context/PsContext";
//import UserMenu from "./userMenu";
import UserMenuNew from "./userMenuNew";
import CartLink from "./cartLink";
import MobileMenuLink from "./mobileMenuLink";
import TopSearchBar from "./topSearchBar";
import LOGO from "../../../assets/img/shibani-logo.png";
import { Modal } from "react-bootstrap";
import LoginForm from "../../pages/userAccount/loginForm";

const Header = () => {
  const context = useContext(PsContext);
  const [show, showModal] = useState(false);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleWindowScroll);
  // });

  // const handleWindowScroll = () => {
  //   var header = document.getElementById("top-header1");

  //   if (header && header != null) {
  //     var sticky = header.offsetTop;

  //     if (window.pageYOffset > sticky) {
  //       header.classList.add("fixed-top");
  //     } else {
  //       header.classList.remove("fixed-top");
  //     }
  //   }
  // };

  return (
    <>
      <header id="top-header1" style={{ marginBottom: "15px" }}>
        <div className="main-header">
          <div className="container-full px_15 lg-px_40">
            <div className="row my-1 wrapper-header logo-area justify-content-center align-items-center">
              <div className="col-xl-4 col-md-4 col-6 text-center tf-lg-hidden">
                <MobileMenuLink />
              </div>{" "}
              <div className="col-xl-4 col-md-4 col-6 text-center tf-md-hidden">
                <TopSearchBar />
              </div>
              <div className="col-xl-4 col-md-4 col-6 text-center d-flex justify-content-center">
                <Link to="/">
                  <a href="" className="logo-header">
                    <img
                      src={LOGO}
                      alt="Logo"
                      style={{ width: "120px" }}
                      className="logo mob-img"
                    />
                  </a>
                </Link>
              </div>
              <div class="col-xl-4 col-md-4 col-3  d-flex justify-content-center mob-icons">
                <ul class="nav-icon gap-20">
                  <CartLink />

                  <li>
                    {context.state.userLogged == "yes" ? (
                      <a
                        href="#"
                        className="nav-link nav-link-sm has-icon my-menu-1"
                        onClick={(e) => {
                          e.preventDefault();
                          const confirmed = window.confirm(
                            "Are you sure you want to log out?"
                          );
                          if (confirmed) {
                            context.userLogout();
                          }
                        }}
                      >
                        <i
                          class="icon icon-account me-2"
                          style={{ color: "#000" }}
                        ></i>{" "}
                        {/* <span
                          className="tf-md-hidden"
                          style={{ fontSize: "13px", color: "#000" }}
                        >
                          Log Out
                        </span> */}
                      </a>
                    ) : (
                      <a
                        href="javascript:;"
                        className="nav-link-sm has-icon my-menu-1"
                        onClick={() => showModal(true)}
                      >
                        <i
                          class="icon icon-account me-2"
                          style={{ color: "#000" }}
                        ></i>
                        {/* <span
                          className="tf-md-hidden"
                          style={{ fontSize: "13px", color: "#000" }}
                        >
                          Log in/Sign in
                        </span> */}
                      </a>
                    )}
                    <Modal
                      show={show}
                      onHide={() => showModal(false)}
                      backdrop="static"
                    >
                      <Modal.Header closeButton>Sign In / Sign Up</Modal.Header>
                      <Modal.Body>
                        <LoginForm afterFinish={() => showModal(false)} />

                        <br />
                        <br />
                      </Modal.Body>
                    </Modal>
                  </li>

                  <li className="mob-order">
                    {context.state.userLogged === "yes" ? (
                      <>
                        <Link to="/user/my-orders">
                          <i className="fas fa-shopping-cart me-2"></i>
                          {/* <span
                            className="tf-md-hidden"
                            style={{ fontSize: "13px", color: "#000" }}
                          >
                            {" "}
                            orders
                          </span> */}
                        </Link>
                      </>
                    ) : (
                      <></>
                    )}
                  </li>
                </ul>
              </div>
              <div className="col-xl-2 md-6 tf-lg-hidden mob-search">
                <TopSearchBar />
              </div>
            </div>
          </div>
        </div>
      </header>
    </>
  );

  return (
    <header id="top-header1">
      <div className="container ">
        <div
          className="row d-flex align-items-center"
          style={{ width: "100%" }}
        >
          <div className="col-md-3 text-center">
            <MobileMenuLink />

            <Link to="/">
              <img src={LOGO} alt="Logo" className="site_header_logo" />
            </Link>

            <span className=" d-md-none">
              <CartLink />

              {context.state.userLogged == "yes" && (
                <UserMenuNew
                  style={{ position: "absolute", top: "13px", right: "20px" }}
                />
              )}
            </span>
          </div>

          <div className="col-md-7 d-none d-sm-block">
            <TopSearchBar />
          </div>

          <div className="col-md-2 d-none d-sm-block ">
            <div className="btn-group" role="group" aria-label="Basic example">
              {/*<a className="btn nav-icon has-badge d-none d-sm-flex" href="account-wishlist.html">
							  <i className="material-icons">favorite_border</i>
							  <span className="badge badge-pill badge-danger">3</span>
						 </a>*/}

              <CartLink />

              {/*context.state.userLogged=='yes' && (<UserMenu />)*/}
              {context.state.userLogged == "yes" && <UserMenuNew />}
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
