import React, { Component } from "react";
import { Link } from "react-router-dom";
import $ from "jquery";

import { Api } from "../../../utils/Variables";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setlsItem, getlsItem } from "../../../utils";
import PsContext from "../../../context/PsContext";
import { Dropdown } from "react-bootstrap";

class Cart extends Component {
  static contextType = PsContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  onRemoveAll(e) {
    if (window.confirm("Do you want to remove all items?")) {
      this.context.updateCart([]);
      toast.success("Successfully removed all items to cart!.");
      this.forceUpdate();
    }
  }

  onRemoveItem(id, e) {
    if (window.confirm("Do you want to remove?")) {
      var d = JSON.parse(getlsItem("cartData"));
      var index = d.findIndex(function (obj) {
        return obj.id == id;
      });
      d.splice(index, 1);
      this.context.updateCart(d);
      toast.success("Card Item removed Successfully!.");
      this.forceUpdate();
    }
  }

  onAddQuantity(id, e) {
    var d = JSON.parse(getlsItem("cartData"));
    var index = d.findIndex(function (obj) {
      return obj.id == id;
    });
    d[index].quantity += 1;
    this.context.updateCart(d);
    this.forceUpdate();
  }

  onMinusQuantity(id, e) {
    var d = JSON.parse(getlsItem("cartData"));
    var index = d.findIndex(function (obj) {
      return obj.id == id;
    });
    if (d[index].quantity > 1) {
      d[index].quantity -= 1;
      this.context.updateCart(d);
      this.forceUpdate();
    }
  }

  handleCheckoutClick() {
    if (this.props.afterFinish) this.props.afterFinish();
  }

  render() {
    let cartList = getlsItem("cartData")
      ? JSON.parse(getlsItem("cartData"))
      : [];
    let total = 0;

    return (
      <div>
        <table
          className="table table-borderless table-cart"
          data-addclass-on-smdown="table-sm"
        >
          <thead>
            <tr className="d-none d-sm-table-row">
              <th>Item</th>
              <th>Name</th>
              <th className="text-center">Price</th>
              <th className="text-center">Qty</th>
              <th className="text-right">Subtotal</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {cartList.map((item, i) => {
              total += parseFloat(item.quantity * item.salesrate);
              return (
                <tr>
                  <td className="cart-img nostretch" style={{ width: "100px" }}>
                    <Link to={"product/" + item.id}>
                      <img
                        className="cart-image"
                        src={Api.CloudImageUrl + "/" + item.image}
                        alt={item.itemname}
                      />
                    </Link>
                  </td>
                  <td className="cart-title">
                    <Link
                      to={"product/" + item.id}
                      className="h6 bold d-inline-block"
                      title={item.itemname}
                    >
                      {item.itemname}
                    </Link>
                    <div className="d-sm-none tf-md-hidden">
                      <i className="lni-tag"></i>{" "}
                      <span className="roboto-condensed bold">
                        ₹ {item.salesrate}
                      </span>
                    </div>
                    {/* <div>
                      <i className="lni-check-mark-cirlce"></i> In Stock
                    </div> */}
                  </td>
                  <td className="cart-price text-center">
                    <div className="price">
                      <span className="text-success">₹ {item.salesrate}</span>
                    </div>
                  </td>
                  <td
                    className="tf-cart-item_quantity"
                    cart-data-title="Quantity"
                  >
                    <div className="cart-quantity d-flex justify-content-center">
                      <div className="wg-quantity">
                        <span
                          className="btn-quantity btndecrease"
                          onClick={() => this.onMinusQuantity(item.id)}
                        >
                          <svg
                            className="d-inline-block"
                            width="9"
                            height="1"
                            viewBox="0 0 9 1"
                            fill="currentColor"
                          >
                            <path d="M9 1H5.14286H3.85714H0V1.50201e-05H3.85714L5.14286 0L9 1.50201e-05V1Z"></path>
                          </svg>
                        </span>
                        <input
                          type="text"
                          name="number"
                          value={item.quantity}
                          readOnly
                        />
                        <span
                          className="btn-quantity btnincrease"
                          onClick={() => this.onAddQuantity(item.id)}
                        >
                          <svg
                            className="d-inline-block"
                            width="9"
                            height="9"
                            viewBox="0 0 9 9"
                            fill="currentColor"
                          >
                            <path d="M9 5.14286H5.14286V9H3.85714V5.14286H0V3.85714H3.85714V0H5.14286V3.85714H9V5.14286Z"></path>
                          </svg>
                        </span>
                      </div>
                    </div>
                  </td>

                  <td className="cart-subtotal text-right">
                    <div className="price">
                      <span>₹ {item.quantity * item.salesrate}</span>
                    </div>
                  </td>
                  <td className="cart-action nostretch pr-0">
                    <Dropdown className="cart-action nostretch pr-0">
                      <Dropdown.Toggle
                        variant="link"
                        className="nav-icon text-secondary p-0"
                      >
                        <i className="lni-more-alt"></i>
                      </Dropdown.Toggle>

                      <Dropdown.Menu align="right">
                        <Dropdown.Item
                          className="text-danger"
                          onClick={() => this.onRemoveItem(item.id)}
                        >
                          <i className="lni-cross-circle"></i> Remove item
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
        <div className="text-right mb-20">
          <div className="btn-group btn-group-sm">
            <button
              data-toggle="tooltip"
              title="Refresh cart"
              className="btn btn-light text-secondary rounded-pill mr-1"
            >
              <i className="lni-reload"></i> Refresh
            </button>
            <button
              data-toggle="tooltip"
              title="Remove all items from cart"
              className="btn btn-light text-secondary rounded-pill"
              onClick={this.onRemoveAll.bind(this)}
            >
              <i className="lni-trash"></i> Remove all
            </button>
          </div>
        </div>
        <div className="row justify-content-between mt-5">
          <div className="col-12 col-sm-6 col-md-5 col-lg-4 col-xl-3 mb-2"></div>
          <div className="col-12 col-md-6 col-lg-5 col-xl-4">
            <div className="d-flex">
              <div className="counter">Order Total</div>
              <div className="ml-auto roboto-condensed bold text-theme h4 mb-20">
                ₹ {parseFloat(total).toFixed(2)}
              </div>
            </div>
          </div>
          <div className="col-12 text-right">
            <div className="btn-group" role="group">
              <Link
                to="/home"
                className="d-none d-sm-inline-block btn rounded-pill mr-1 btn-outline-themes"
              >
                Continue Shopping
              </Link>
              <button
                className={
                  cartList.length == 0
                    ? "btn rounded-pill btn-themes disabled"
                    : "btn rounded-pill btn-themes"
                }
                onClick={this.handleCheckoutClick.bind(this)}
              >
                Procees to Checkout
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Cart;
