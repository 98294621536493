import React, { useState } from "react";
const AnnouncementBar = () => {
  const [isVisible, setIsVisible] = useState(true);

  const closeBar = () => {
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="announcement-bar tf-md-hidden">
        <div className="wrap-announcement-bar">
          <div className="box-sw-announcement-bar speed-1">
            <div className="announcement-bar-item">
              <p>FREE SHIPPING AND RETURNS</p>
            </div>
            <div className="announcement-bar-item">
              <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
            </div>
            <div className="announcement-bar-item">
              <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
            </div>
            <div className="announcement-bar-item">
              <p>FREE SHIPPING AND RETURNS</p>
            </div>
            <div className="announcement-bar-item">
              <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
            </div>
            <div className="announcement-bar-item">
              <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
            </div>
            <div className="announcement-bar-item">
              <p>FREE SHIPPING AND RETURNS</p>
            </div>
            <div className="announcement-bar-item">
              <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
            </div>
            <div className="announcement-bar-item">
              <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
            </div>
            <div className="announcement-bar-item">
              <p>FREE SHIPPING AND RETURNS</p>
            </div>
            <div className="announcement-bar-item">
              <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
            </div>
            <div className="announcement-bar-item">
              <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
            </div>
            <div className="announcement-bar-item">
              <p>FREE SHIPPING AND RETURNS</p>
            </div>
            <div className="announcement-bar-item">
              <p>NEW SEASON, NEW STYLES: FASHION SALE YOU CAN'T MISS</p>
            </div>
            <div className="announcement-bar-item">
              <p>LIMITED TIME OFFER: FASHION SALE YOU CAN'T RESIST</p>
            </div>
          </div>
        </div>
        <span
          className="icon-close close-announcement-bar"
          onClick={closeBar}
        ></span>
      </div>
    )
  );
};

export default AnnouncementBar;
