import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Api } from "../../../utils/Variables";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { setlsItem, getlsItem } from "../../../utils";
import PsContext from "../../../context/PsContext";

class OrderSummary extends Component {
  static contextType = PsContext;

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let cartList = getlsItem("cartData")
      ? JSON.parse(getlsItem("cartData"))
      : [];
    let total = 0;

    return (
      <div>
        <div className="tf-page-title mb-50">
          <div className="container-full">
            <div className="heading text-center">Order Summary</div>
            <p className="text-center text-2 text_black-2 mt_5">my orders</p>
          </div>
        </div>
        {cartList.map((item, i) => {
          total += parseFloat(item.quantity * item.salesrate);
          return (
            <div className="media border-top py-2">
              <Link to={"/product/" + item.id}>
                <img
                  width="50"
                  src={Api.CloudImageUrl + "/" + item.image}
                  alt={item.itemname}
                />
              </Link>
              <div className="media-body ml-3">
                <Link
                  to={"/product/" + item.id}
                  className="h6 bold mb-0"
                  title={item.itemname}
                >
                  {item.itemname}
                </Link>
                <div className="price">
                  <i className="lni-tag"> </i>
                  <span className="text-success">Rs. {item.salesrate}</span>
                  <span className="ml-3 text-secondary">x {item.quantity}</span>
                  <span className="ml-3">
                    ₹ {item.quantity * item.salesrate}
                  </span>
                </div>
              </div>
            </div>
          );
        })}
        {this.props.showFooter ? (
          <React.Fragment>
            <hr />
            <div className="d-flex">
              <div className="counter">Order Total</div>
              <div className="ml-auto roboto-condensed bold text-primary h4 mb-0">
                Rs. {parseFloat(total).toFixed(2)}
              </div>
            </div>
          </React.Fragment>
        ) : (
          ""
        )}
      </div>
    );
  }
}
export default OrderSummary;
